<template>
    <div @click="loginWithLinkedin()" class="btn-linkedin">
        <img src="../../assets/linkedin.svg" alt="linkedin" />
        <div class="btn-linkedin--text">
            <b-spinner v-if="loadingProvider === 'linkedin'" class="primary-text mr-2 loader-spinner" small></b-spinner>
            <p v-else>{{ $l("Platon.sign_in_with_linkedin", "LinkedIn орқали кириш") }}</p>
        </div>
    </div>
</template>
<script>
import LoginMixin from "@Platon/components/login/LoginMixin"

export default {
    name: "LinkedinProvider",
    mixins: [LoginMixin],

    mounted() {
        this.loginValidation()
    }
}
</script>
<style lang="scss">
.btn-linkedin {
    display: flex;
    align-items: center;
    background: #1677b5;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 14px;
    &:hover {
        background: #3187bc;
    }
    &:active {
        background: #11659a;
    }
    &--text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    p {
        margin: 0;
        color: white;
    }
}
</style>
